var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { appear: "", name: "fade" } }, [
    _c(
      "div",
      { staticClass: "d-flex flex-column" },
      [
        _c(
          "div",
          { staticClass: "d-flex justify-content-between" },
          [
            _c("div", { attrs: { id: "space-filler" } }),
            _c("appAdminSidebar"),
            _c("appHeader", {})
          ],
          1
        ),
        _c("div", { staticClass: "d-flex justify-content-center mb-3" }, [
          _c("div", { style: { width: "90%" } }, [
            _c("h2", [_vm._v("Add One Card at a Time")]),
            _c("div", { staticClass: "add-cards rounded p-2" }, [
              _c("div", { staticClass: "row pt-2" }, [
                _c(
                  "div",
                  { staticClass: "my-1 col-lg col-sm-4" },
                  [
                    _c("label", [_vm._v("User name")]),
                    _c("b-form-input", {
                      staticClass: "form-control",
                      attrs: { min: "0", required: "", type: "text" },
                      model: {
                        value: _vm.newCardName,
                        callback: function($$v) {
                          _vm.newCardName = $$v
                        },
                        expression: "newCardName"
                      }
                    }),
                    _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v("Required")
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-1 col-lg col-sm-4" },
                  [
                    _c("label", [_vm._v("Email")]),
                    _c("b-form-input", {
                      staticClass: "form-control",
                      attrs: { min: "0", required: "", type: "email" },
                      model: {
                        value: _vm.newCardEmail,
                        callback: function($$v) {
                          _vm.newCardEmail = $$v
                        },
                        expression: "newCardEmail"
                      }
                    }),
                    _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v("Required")
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-1 col-lg col-sm-6" },
                  [
                    _c("label", [_vm._v("Brand")]),
                    _c("b-form-select", {
                      staticClass: "form-control",
                      staticStyle: { "background-color": "white" },
                      attrs: {
                        id: "card-type-dropdown",
                        options: _vm.cardOptions,
                        required: ""
                      },
                      model: {
                        value: _vm.newCardPrepaidType,
                        callback: function($$v) {
                          _vm.newCardPrepaidType = $$v
                        },
                        expression: "newCardPrepaidType"
                      }
                    }),
                    _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v("Required")
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "my-1 col-lg col-sm-2" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "showtracking" }
                      },
                      [_vm._v("Send Email")]
                    ),
                    _c("b-form-checkbox", {
                      staticClass: "ml-2",
                      attrs: {
                        switch: "",
                        "false-value": false,
                        "true-value": true,
                        role: "switch"
                      },
                      model: {
                        value: _vm.sendEmail,
                        callback: function($$v) {
                          _vm.sendEmail = $$v
                        },
                        expression: "sendEmail"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "col-lg-1 col-sm-1" })
              ]),
              _vm.currentCardDetail
                ? _c("div", { staticClass: "bs-component pt-2" }, [
                    _c("h6", [_vm._v("Product Detail")]),
                    _c("div", { staticClass: "pb-2" }, [
                      _c("img", {
                        attrs: {
                          src:
                            _vm.currentCardDetail.item.imageUrls[
                              "1200w-326ppi"
                            ],
                          height: "75px",
                          width: "auto"
                        }
                      }),
                      _vm._v(
                        " " + _vm._s(_vm.currentCardDetail.item.brandName) + " "
                      )
                    ]),
                    _vm.currentCardDetail.item.items[0].valueType !=
                    "VARIABLE_VALUE"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "btn-group btn-group-toggle d-flex flex-wrap",
                            attrs: { "data-toggle": "buttons" }
                          },
                          [
                            _vm._l(_vm.currentCardDetail.item.items, function(
                              item
                            ) {
                              return _c(
                                "label",
                                { staticClass: "btn btn-secondary" },
                                [
                                  _c("input", {
                                    attrs: { type: "radio" },
                                    domProps: { value: item.utid },
                                    on: {
                                      click: function($event) {
                                        return _vm.denominationChange(item)
                                      }
                                    }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(item.currencyCode) +
                                      " " +
                                      _vm._s(item.faceValue) +
                                      " "
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      : _c("div", { staticClass: "pt-2" }, [
                          _c(
                            "div",
                            { staticClass: "pb-2" },
                            [
                              _c("label", [_vm._v("Amount")]),
                              _c("b-form-input", {
                                staticClass: "form-control",
                                style: { width: "50%" },
                                attrs: { min: "0", required: "", type: "text" },
                                on: {
                                  change: function($event) {
                                    return _vm.denominationChange(
                                      _vm.currentCardDetail.item.items[0]
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.rangeSlider,
                                  callback: function($$v) {
                                    _vm.rangeSlider = $$v
                                  },
                                  expression: "rangeSlider"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.currentCardDetail.item.items[0].minValue
                              )
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.rangeSlider,
                                expression: "rangeSlider"
                              }
                            ],
                            staticClass: "form-range",
                            style: { width: "90%" },
                            attrs: {
                              type: "range",
                              min: _vm.currentCardDetail.item.items[0].minValue,
                              max: _vm.currentCardDetail.item.items[0].maxValue
                            },
                            domProps: { value: _vm.rangeSlider },
                            on: {
                              change: function($event) {
                                return _vm.denominationChange(
                                  _vm.currentCardDetail.item.items[0]
                                )
                              },
                              __r: function($event) {
                                _vm.rangeSlider = $event.target.value
                              }
                            }
                          }),
                          _vm._v(
                            _vm._s(
                              _vm.currentCardDetail.item.items[0].maxValue
                            ) + " "
                          ),
                          _c("br"),
                          _vm._v("Amount: " + _vm._s(_vm.rangeSlider) + " ")
                        ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "mt-3" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-md btn-sm-block",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.prepareNewCard()
                      }
                    }
                  },
                  [_vm._v(" Add one more card ")]
                )
              ])
            ]),
            _c("div", { staticClass: "card-container text-right mt-2" })
          ])
        ]),
        _vm.errorStrings && _vm.errorStrings.length > 0
          ? _c(
              "div",
              {
                staticClass: "align-self-center text-danger",
                attrs: { id: "errors" }
              },
              [
                _c("p", { key: _vm.errorStrings.length }, [
                  _vm._v("ERRORS FOUND!")
                ]),
                _vm._l(_vm.errorStrings, function(error) {
                  return _c("li", [_vm._v(" " + _vm._s(error) + " ")])
                })
              ],
              2
            )
          : _vm._e(),
        _vm.preparedNewCards.length > 0
          ? _c("b-table", {
              staticClass: "mx-5",
              attrs: {
                fields: _vm.fields,
                fixed: "",
                striped: "",
                bordered: "",
                items: _vm.preparedNewCards
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "cell(Actions)",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-tertiary btn-md btn-sm-block mr-3",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.removePreparedNewCard(item)
                              }
                            }
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "mr-1",
                              attrs: {
                                size: "lg",
                                icon: "times",
                                transform: "enlarge-1"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                4144376288
              )
            })
          : _vm._e(),
        _vm.preparedNewCards.length > 0
          ? _c(
              "b-button",
              {
                staticClass: "flex-grow-0 align-self-end mx-5",
                attrs: {
                  variant: "primary",
                  type: "button",
                  disabled: !_vm.canGoToNextStep
                },
                on: { click: _vm.nextStep }
              },
              [_vm._v(" Next step ")]
            )
          : _vm._e(),
        _c("appFooter", { staticClass: "pt-4 mt-5" })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }